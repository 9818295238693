

// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

(function ($) {
    function process_bdesign2022_block_pageheader() {
        if ($('.bdesign2022_ph-wrap.is-slide').length >= 0) {
            //console.log('process_bdesign2022_block_pageheader \t slider found - processing ...');
            $('.bdesign2022_ph-wrap.is-slide').find('.carousel-item').first().addClass('active');
            //console.log('process_bdesign2022_block_pageheader \t slider found - ... started');
        }
    }

    //console.log('process_bdesign2022_block_pageheader \t init');
    setTimeout(function(){
        process_bdesign2022_block_pageheader();
    },500);
    //console.log('process_bdesign2022_block_pageheader \t done');
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// Init the Navbar BG

(function ($) {
    function process_bdesign2022_navbar() {
        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();
            if(scroll >= 1){
                $('#site-navbar').addClass('is-highlight');
            } else {
                $('#site-navbar').removeClass('is-highlight');
            }
        });
    }

    //console.log('process_bdesign2022_navbar \t\t\t\t init');
    process_bdesign2022_navbar()
    //console.log('process_bdesign2022_navbar \t\t\t\t done');
})(jQuery);

// -- -- -- -- -- -- -- -- -- -- --
// SYNC THE TEXTAREA TOP SPACE

(function ($) {
    function process_bdesign2022_formsync() {
        if (screen.width > 767 && $('#wsf-1-field-wrapper-1').length) {
            setTimeout(function() {
                var top = $('#wsf-1-field-wrapper-1').offset();
                var topparent = $('#wsf-1-field-wrapper-12').offset();
                var margintop = top.top - topparent.top;
                $('#wsf-1-fields-2').css('margin-top' , margintop);
                //console.log( margintop );
            }, 500);
        }
    }

    process_bdesign2022_formsync()
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// Fix Produktverteiler linkage

(function ($) {
    //console.log('bdesign2022_produktverteiler \t\t\t init');
    setTimeout(function(){
        $('.bdesign2022_block.bdesign2022_block--bdesign2022_produktverteiler .product_element-container a').on('click',function(){
            window.location = $(this).attr("href");
            return false;
        });
        //console.log('bdesign2022_produktverteiler \t\t\t done');
        $('#bdesign2022_produktverteiler_slider_block_62e2719dede2e').carousel({
            interval: 3000,
            wrap: true,
            pause: false
        });
    },500);
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// Fix Last Element Margin-bottom

(function ($) {
    //console.log('bdesign2022_pagefix \t\t\t\t\t init');
    setTimeout(function(){
        $('#site-main section.entry-content > div').last().css({'margin-bottom': 0});
        $('#site-main section.article-content > div').last().css({'margin-bottom': 0});
    },500);
    //console.log('bdesign2022_pagefix \t\t\t\t\t done');
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// Add Modal Call!

(function ($) {
    //console.log('bdesign2022_langModal \t\t\t\t\t init');
    setTimeout(function(){
        $('#navbarMain .btn-bdesign2022.btn-language a').attr('data-bs-toggle','modal').attr('data-bs-target','#bdesign2022LangModal');
        $('#site-navbar .btn-bdesign2022.btn-language').attr('data-bs-toggle','modal').attr('data-bs-target','#bdesign2022LangModal');
    },500);
    //console.log('bdesign2022_langModal \t\t\t\t\t done');
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide for Clickcontents

(function ($) {
    function process_bdesign2022_block_imagetext() {

        var itemsMainDiv = ('.clickcontent-carousel');
        var itemsDiv = ('.clickcontent-carousel .carousel-inner');
        var itemWidth = "";

        function ResCarouselSize_imagetext() {
            var incno = 0;
            var dataItems = ("data-items");
            var itemClass = ('.clickcontent-item');
            var id = 0;
            var btnParentSb = '';
            var itemsSplit = '';
            var sampwidth = $('.container').eq(0).outerWidth();
            var bodyWidth = $('body').width();

            $(itemsDiv).each(function () {
                id = id + 1;
                var itemNumbers = $(this).find(itemClass).length;
                btnParentSb = $(this).parent().attr(dataItems);
                itemsSplit = btnParentSb.split(',');
                $(this).parent().attr("id", "clickcontent-carousel-multi" + id);

                if (bodyWidth >= 1200) {
                    incno = itemsSplit[3];
                    itemWidth = sampwidth / incno;
                }
                else if (bodyWidth >= 992) {
                    incno = itemsSplit[2];
                    itemWidth = sampwidth / incno;
                }
                else if (bodyWidth >= 768) {
                    incno = itemsSplit[1];
                    itemWidth = sampwidth / incno;
                }
                else {
                    incno = itemsSplit[0];
                    itemWidth = sampwidth / incno;
                }

                $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
                $(this).find(itemClass).each(function () {
                    $(this).outerWidth(itemWidth);
                });

                $(".clickcontent-carousel .leftLst").addClass("over");
                $(".clickcontent-carousel .rightLst").removeClass("over");

            });

            //console.log('process_bdesign2022_imagetext \t\t\t ResCarouselSize_imagetext()');

        }
        var translateXval = '';
        function ResCarousel_imagetext(e, el, s) {

            var leftBtn = ('.clickcontent-carousel .leftLst');
            var rightBtn = ('.clickcontent-carousel .rightLst');
            var divStyle = $(el + ' .carousel-inner').css('transform');
            var values = divStyle.match(/-?[\d\.]+/g);

            var xds = Math.abs(values[4]);

            if (e == 0) {
                translateXval = parseInt(xds) - parseInt(itemWidth * s);
                $(el + '' + rightBtn).removeClass("over");

                if (translateXval <= itemWidth / 2) {
                    translateXval = 0;
                    $(el + '' + leftBtn).addClass("over");
                }
            }
            else if (e == 1) {
                var itemsCondition = $(el).find('.carousel-inner').width() - $(el).width();
                translateXval = parseInt(xds) + parseInt(itemWidth * s);
                $(el + '' + leftBtn).removeClass("over");

                if (translateXval >= itemsCondition - itemWidth / 2) {
                    translateXval = itemsCondition;
                    $(el + '' + rightBtn).addClass("over");
                }
            }
            setTimeout(function(){
                if ($(el + '' + rightBtn).hasClass('over')) {
                    $(el + '' + rightBtn + '.over').addClass('loop');
                }
                if ($(el + '' + leftBtn).hasClass('over')) {
                    $(el + '' + leftBtn + '.over').addClass('loop');
                }
            },500);
            $(el + '' + rightBtn + '.over').click(function() {
                setTimeout(function(){
                    if ($(el + '' + rightBtn).hasClass('loop')) {
                        $(el + ' .carousel-inner').css('transform', 'translateX(0px)');
                        $(el + '' + rightBtn + '.over').removeClass('over');
                        $(el + '' + rightBtn + '.loop').removeClass('loop');
                        $('.clickcontent-carousel .leftLst').addClass('over');
                        $('.clickcontent-carousel .leftLst').addClass('loop');
                    }
                },250);
            })


            $(el + ' .carousel-inner').css('transform', 'translateX(' + -translateXval + 'px)');

        }

        $('.clickcontent-carousel .rightLst').click(function(){
            var parent = "#" + $(this).parent().attr("id");
            var slide = $(parent).attr("data-slide");

            if(typeof triggered == 'undefined'){
                triggered = false;
            }

            if(!triggered){
                triggered = true;
                ResCarousel_imagetext(1, parent, slide);

                setTimeout(function(){
                    triggered = false;
                },500);
            }
        });

        $('.clickcontent-carousel .leftLst').click(function(){
            var parent = "#" + $(this).parent().attr("id");
            var slide = $(parent).attr("data-slide");

            if(typeof triggered == 'undefined'){
                triggered = false;
            }

            if(!triggered){
                triggered = true;
                ResCarousel_imagetext(0, parent, slide);

                setTimeout(function(){
                    triggered = false;
                },500);
            }
            //console.log('cloced');




            var itemsMainDiv = ('.clickcontent-carousel');
            var itemsDiv = ('.clickcontent-carousel .carousel-inner');
            var itemWidth = "";
            var incno = 0;
            var dataItems = ("data-items");
            var itemClass = ('.clickcontent-item');
            var id = 0;
            var btnParentSb = '';
            var itemsSplit = '';
            var sampwidth = $('.container').eq(0).outerWidth();
            var bodyWidth = $('body').width();

            $(itemsDiv).each(function () {
                id = id + 1;
                var itemNumbers = $(this).find(itemClass).length;
                btnParentSb = $(this).parent().attr(dataItems);
                itemsSplit = btnParentSb.split(',');

                if (bodyWidth >= 1200) {
                    incno = itemsSplit[3];

                }
                else if (bodyWidth >= 992) {
                    incno = itemsSplit[2];

                }
                else if (bodyWidth >= 768) {
                    incno = itemsSplit[1];

                }
                else {
                    incno = itemsSplit[0];

                }

            });

            setTimeout(function(){
                if ($('.clickcontent-carousel .leftLst').hasClass('loop')) {
                    var itemNumbers = $('.clickcontent-carousel .carousel-inner').find('.clickcontent-item').length;
                    if (incno == 1) {
                        incno = itemNumbers - 1;
                    }

                    if (incno == 2) {
                        incno = itemNumbers - 2;
                    }

                    var widthitem = $('.clickcontent-carousel .carousel-inner .clickcontent-item').width();
                    translateXval = widthitem * incno;
                    $('.clickcontent-carousel .leftLst.over').removeClass('over');
                    $('.clickcontent-carousel .leftLst.loop').removeClass('loop');
                    $('.clickcontent-carousel .carousel-inner').css('transform', 'translateX(-' + translateXval + 'px)');

                    $('.clickcontent-carousel .rightLst').addClass('over');
                    $('.clickcontent-carousel .rightLst').addClass('loop');
                }
            },250);

        });

        $(window).resize(function () {
            ResCarouselSize_imagetext();
        });

        ResCarouselSize_imagetext();

    }

    //console.log('process_bdesign2022_imagetext \t\t\t init');
    setTimeout(function(){
        process_bdesign2022_block_imagetext();
    },500);
    //console.log('process_bdesign2022_imagetext \t\t\t done');
})(jQuery);



// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide for Products

(function ($) {
    function process_bdesign2022_block_productslider() {

        var itemsMainDiv = ('.productslider-carousel');
        var itemsDiv = ('.productslider-carousel .carousel-inner');
        var itemWidth = "";

        function ResCarouselSize_productslider() {
            var incno = 0;
            var dataItems = ("data-items");
            var itemClass = ('.productcontent-item');
            var id = 0;
            var btnParentSb = '';
            var itemsSplit = '';
            var sampwidth = $('section > div').not('.d-none').find('.container > .row').eq(0).outerWidth(true);
            var bodyWidth = $('body').width();

            $(itemsDiv).each(function () {
                id = id + 1;
                var itemNumbers = $(this).find(itemClass).length;
                btnParentSb = $(this).parent().attr(dataItems);
                itemsSplit = btnParentSb.split(',');
                $(this).parent().attr("id", "productslider-carousel-multi-" + id);

                if (bodyWidth >= 1200) {
                    incno = itemsSplit[3];
                    itemWidth = sampwidth / incno;
                }
                else if (bodyWidth >= 992) {
                    incno = itemsSplit[2];
                    itemWidth = sampwidth / incno;
                }
                else if (bodyWidth >= 768) {
                    incno = itemsSplit[1];
                    itemWidth = sampwidth / incno;
                }
                else {
                    incno = itemsSplit[0];
                    itemWidth = sampwidth / incno;
                }

                $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });

                $(this).find(itemClass).each(function () {
                    $(this).outerWidth(itemWidth);
                });

                $(".productslider-carousel .leftLst").addClass("over");
                $(".productslider-carousel .rightLst").removeClass("over");

            });

            //console.log('process_bdesign2022_productslider \t\t ResCarouselSize_productslider()');

        }

        function ResCarousel_productslider(e, el, s) {

            var leftBtn = ('.productslider-carousel .leftLst');
            var rightBtn = ('.productslider-carousel .rightLst');
            var translateXval = '';
            var divStyle = $(el + ' .carousel-inner').css('transform');
            var values = divStyle.match(/-?[\d\.]+/g);

            var xds = Math.abs(values[4]);

            if (e == 0) {
                translateXval = parseInt(xds) - parseInt(itemWidth * s);
                $(el + '' + rightBtn).removeClass("over");

                if (translateXval <= itemWidth / 2) {
                    translateXval = 0;
                    $(el + '' + leftBtn).addClass("over");
                }
            }

            else if (e == 1) {
                var itemsCondition = $(el).find('.carousel-inner').width() - $(el).width();
                translateXval = parseInt(xds) + parseInt(itemWidth * s);
                $(el + '' + leftBtn).removeClass("over");

                if (translateXval >= itemsCondition - itemWidth / 2) {
                    translateXval = itemsCondition;
                    $(el + '' + rightBtn).addClass("over");
                }
            }

            $(el + ' .carousel-inner').css('transform', 'translateX(' + -translateXval + 'px)');

        }

        $('.productslider-carousel .rightLst').click(function(){
            var parent = "#" + $(this).parent().attr("id");
            var slide = $(parent).attr("data-slide");
            ResCarousel_productslider(1, parent, slide);
        });

        $('.productslider-carousel .leftLst').click(function(){
            var parent = "#" + $(this).parent().attr("id");
            var slide = $(parent).attr("data-slide");
            ResCarousel_productslider(0, parent, slide);
        });

        $(window).resize(function () {
            ResCarouselSize_productslider();
        });

        ResCarouselSize_productslider();

    }

    //console.log('process_bdesign2022_productslider \t\t init');
    setTimeout(function(){
        process_bdesign2022_block_productslider();
    },500);
    //console.log('process_bdesign2022_productslider \t\t done');
})(jQuery);


// -- -- -- -- -- -- -- -- -- -- --
// The Modal Magic!

function bdesign2022_modalMagic(event){
    ////console.log('bdesign2022_modalMagic \t\t\t\t\t -> ' + event);
}
